import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: 'a[target=_blank]',
})
export class SafeBlankDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.renderer.setAttribute(
      this.el.nativeElement,
      'rel',
      'noopener noreferrer'
    );
  }
}
